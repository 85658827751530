import { Racer } from '@comher.de/game-engine';
import { falconImg, hornetImg, kartImg, scorpionImg, turtleImg } from '../assets/img/racers';
import { falconGalleryImg, hornetGalleryImg, kartGalleryImg, scorpionGalleryImg, turtleGalleryImg } from 'assets/img/racers/gallery';

export type MenuRacer = Racer & { galleryImgSrc: string };

export const FALCON: MenuRacer = {
    name: 'Falcon',
    imgUrl: falconImg,
    galleryImgSrc: falconGalleryImg,
    maxAcceleration: 100,
    maxBreakPower: 120,
    maxSteering: 0.1,
    maxSpeed: 320,
    getActualGrip: (baseGrip: number) => {
        return baseGrip * baseGrip;
    },
};

export const KART: MenuRacer = {
    name: 'Kart',
    imgUrl: kartImg,
    galleryImgSrc: kartGalleryImg,
    maxAcceleration: 30,
    maxBreakPower: 60,
    maxSteering: 0.135,
    maxSpeed: 120,
    getActualGrip: (baseGrip: number) => {
        return baseGrip;
    },
};

export const SCORPION: MenuRacer = {
    name: 'Scorpion',
    imgUrl: scorpionImg,
    galleryImgSrc: scorpionGalleryImg,
    maxAcceleration: 60,
    maxBreakPower: 100,
    maxSteering: 0.1,
    maxSpeed: 250,
    getActualGrip: (baseGrip: number) => {
        return baseGrip;
    },
};

export const TURTLE: MenuRacer = {
    name: 'Turtle',
    imgUrl: turtleImg,
    galleryImgSrc: turtleGalleryImg,
    maxAcceleration: 40,
    maxBreakPower: 100,
    maxSteering: 0.12,
    maxSpeed: 200,
    getActualGrip: (baseGrip: number) => {
        return baseGrip;
    },
};

export const HORNET: MenuRacer = {
    name: 'Hornet',
    imgUrl: hornetImg,
    galleryImgSrc: hornetGalleryImg,
    maxAcceleration: 80,
    maxBreakPower: 100,
    maxSteering: 0.1,
    maxSpeed: 250,
    getActualGrip: (baseGrip: number) => {
        return Math.sqrt(Math.sqrt(baseGrip));
    },
};
