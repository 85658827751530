import { Colors } from '../../../theme/Colors';
import { useProgress } from 'use-functions/useProgress';
import Modal from 'components/shared/modal/Modal';
import { bannerWide } from 'assets/img/banner';
import { useAppSize } from 'use-functions/useAppSize';
import { useEffect, useState } from 'react';
import { LS_KEY_LOG_LEVEL, Logging } from 'util/Logging';
import { background } from 'assets/img';
import { SizeObserver } from 'util/SizeObserverUtil';
import { ProgressManager } from 'util/ProgressManager';
import { LocalState } from 'util/LocalState';
import { useObservableState } from 'use-functions/useObservableState';

export const LoadingModal = (props: { sizeObserver: SizeObserver; progressManager: ProgressManager; localState: LocalState }) => {
    const { sizeObserver, progressManager, localState } = props;

    const { width, height } = useAppSize(sizeObserver);
    const imgWidth = width * 0.98;

    const progress = useProgress(progressManager);

    const evaluate = () => {
        const logLevel = Logging.getLogLevel();
        const debug = logLevel == 'DEBUG';
        return debug;
    };

    const debug = useObservableState({
        observable: localState.observe(LS_KEY_LOG_LEVEL),
        evaluate,
        initialResult: evaluate(),
        initialObserved: null,
    });

    const [details, setDetails] = useState(progressManager.getProgressSourcesDetails());
    useEffect(() => {
        setDetails(progressManager.getProgressSourcesDetails());
    }, [progress]);

    return (
        <Modal dataTestId="LoadingModal" onTop open={progress < 1} sizeObserver={sizeObserver} fullScreen={true}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'inherit',
                    boxSizing: 'border-box',
                }}
            >
                {debug && (
                    <div
                        style={{
                            position: 'absolute',
                            fontSize: '0.75em',
                            textAlign: 'center',
                            marginTop: height * 0.2,
                            backgroundColor: Colors.black,
                        }}
                    >
                        {details
                            .filter((d) => d.progress < 1)
                            .map((d) => d.name + ': ' + (d.progress * 100).toFixed(0) + '%')
                            .map((d, i) => (
                                <div key={i}>{d}</div>
                            ))}
                    </div>
                )}

                <img src={bannerWide} style={{ width: imgWidth, marginTop: height * 0.1 }} />
                <img
                    src={background}
                    style={{ width: imgWidth, height: height * 0.5, objectFit: 'cover', position: 'relative', bottom: -height * 0.015 }}
                />
            </div>
            <div style={{ position: 'absolute', fontSize: '1.5em', textAlign: 'center', marginTop: height * 0.3 }}>
                Loading :&nbsp;
                <span style={{ color: Colors.neutral }}>{(progress * 100).toFixed(0) + '%'}</span>
            </div>
        </Modal>
    );
};
