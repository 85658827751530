import { ASPHALT, DIRT, GRASS } from '../GroundConfigs';
import { FALCON, TURTLE } from '../Racers';
import { RaceConfig } from '../../types/RaceConfig';
import {
    asphaltImg,
    ceilingImg,
    checkpointsImg,
    checkpointsSmallImg,
    dirtImg,
    grassImg,
    itemsImg,
    obstaclesImg,
    obstaclesSmallImg,
} from '../../assets/img/tracks/Springfield-Raceway';

const HALF_WIDTH = 1194 / 2;
const HALF_HEIGHT = 1349 / 2;

const SHARED = {
    trackId: 'SR',
    orderedGrounds: [
        {
            ...DIRT,
            imgUrl: dirtImg,
        },
        {
            ...ASPHALT,
            imgUrl: asphaltImg,
        },
        {
            ...GRASS,
            imgUrl: grassImg,
        },
    ],
    ceilingImgUrl: ceilingImg,
    itemsImgUrl: itemsImg,
    startRacerState: {
        position: { x: 317 - HALF_WIDTH, y: -509 + HALF_HEIGHT },
        direction: Math.PI * 1.01,
        speed: 0,
    },
    itemsCount: 0,
    defaultGrip: GRASS.grip,
    numItems: 5,
};

export const SPRINGFIELD_RW_INTRO: RaceConfig = {
    id: 'sri',
    name: 'Springfield Small',
    heroTime: Math.ceil(19.48 * 60),

    checkPointsConfig: {
        numLaps: 1,
        imgUrl: checkpointsSmallImg,
    },
    collisionImgUrl: obstaclesSmallImg,
    racer: TURTLE,
    // defaultCompressedGhost:
    //     'O66«5O55«7O24«8O51«8O14«7O23«7O20«12O7«15<2o17<12(11<22o8<23«13o8<11«4O21»6O30»7O15o3>8o30>10o4>20o10>30o10>14o3O7»19O13»24O7»9O4o13<11o18O8«7<18«12<3o11<12«6O3o11<8«9<8«9<2o3O3o2O54«11O5o13O10«13O13«15O10«33O9o10O5«3<9o14O19<24o12<17o7O13o4<11o13<8o41<6o8O16»6O17«9O4',
    ...SHARED,
};

export const SPRINGFIELD_RW_SHOWDOWN: RaceConfig = {
    id: 'srs',
    name: 'Springfield Raceway',
    heroTime: Math.ceil(65.45 * 60),

    checkPointsConfig: {
        numLaps: 3,
        imgUrl: checkpointsImg,
    },
    collisionImgUrl: obstaclesImg,
    racer: FALCON,
    ...SHARED,
};
