import {
    asphaltImg,
    dirtImg,
    obstaclesImg,
    ceilingImg,
    itemsImg,
    RockyHillCheckpointsImg,
    grassImg,
    RockyHillIntroCheckointsImg,
} from '../../assets/img/tracks/Rocky-Hill';
import { ASPHALT, DIRT, GRASS } from '../GroundConfigs';
import { FALCON, SCORPION, TURTLE } from '../Racers';
import { RaceConfig } from '../../types/RaceConfig';

const HALF_WIDTH = 557 / 2;
const HALF_HEIGHT = 1003 / 2;

const SHARED = {
    trackId: 'R',
    orderedGrounds: [
        {
            ...DIRT,
            imgUrl: dirtImg,
        },
        {
            ...ASPHALT,
            imgUrl: asphaltImg,
        },
        {
            ...GRASS,
            imgUrl: grassImg,
        },
    ],
    collisionImgUrl: obstaclesImg,
    ceilingImgUrl: ceilingImg,
    itemsImgUrl: itemsImg,
    startRacerState: {
        position: { x: 220, y: -240 },
        direction: 0,
        speed: 0,
    },
    itemsCount: 0,
    defaultGrip: GRASS.grip,
    numItems: 5,
};

export const ROCKY_HILL_INTRO: RaceConfig = {
    id: 'r',
    name: 'Rocky-Hill-Intro',
    heroTime: Math.ceil(6.85 * 60),

    checkPointsConfig: {
        numLaps: 1,
        imgUrl: RockyHillIntroCheckointsImg,
    },
    racer: TURTLE,
    ...SHARED,
    startRacerState: {
        position: { x: 230 - HALF_WIDTH, y: -840 + HALF_HEIGHT },
        direction: -Math.PI / 7,
        speed: 0,
    },
};

export const ROCKY_HILL: RaceConfig = {
    id: 'R',
    name: 'Rocky-Hill',
    heroTime: Math.ceil(30.03 * 60),

    checkPointsConfig: {
        numLaps: 2,
        imgUrl: RockyHillCheckpointsImg,
    },
    racer: SCORPION,
    // defaultCompressedGhost:
    //     'O32«11O17«8O4o9O17«13O3o3O4«12O4o8O11»11O1o11>5»3O5o13»5O5o11O11o12O7»7O4o62.12(16<3o12<14«19<63«15O17«8O55o71<8o22<7o12<9o8<8o19>12)11>2o12>10»30>81o5<46«87O10«33O9«32<3o18<18o22<19o19<13o11<6«30<1o28>11o6>10o14>7o11O14«10O3o8>8o24O3»12O3o15.17(16<7o10<21o4O9«5<24«73O44»5O30o23<9o30<9o3<8o7.24o16>151o1<36«9<13«76O7«49O10«25<2o21<14o8<16o24<7o10<9',
    ...SHARED,
};

export const ROCKY_HILL_SHOWDOWN: RaceConfig = {
    id: 'H',
    name: 'Rocky-Hill-Showdown',
    heroTime: Math.ceil(43.8 * 60),

    checkPointsConfig: {
        numLaps: 3,
        imgUrl: RockyHillCheckpointsImg,
    },
    racer: FALCON,
    ...SHARED,
};

export const ROCKY_HILL_ENDURANCE: RaceConfig = {
    id: 'h',
    name: 'Rocky-Hill-Endurance',
    heroTime: Math.ceil(307.78 * 60),

    checkPointsConfig: {
        numLaps: 20,
        imgUrl: RockyHillCheckpointsImg,
    },
    racer: SCORPION,
    ...SHARED,
};
