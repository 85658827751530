import { Observable } from '@comher.de/commons';
import { ProgressSource } from 'types/ProgressSource';

export class PreloadImageProgressSource implements ProgressSource {
    private progress = 0;
    public onProgressChanged = new Observable<number>();
    public name;

    public promise = new Promise<any>((resolve, reject) => {
        this.onProgressChanged.attach(async (progress) => {
            if (progress === 1) {
                resolve(undefined);
            }
        });
    });

    constructor(url: string) {
        const img = new Image();
        this.name = 'Preload ' + url;
        img.src = url;

        img.onload = () => {
            this.progress = 1;
            this.onProgressChanged.notify(this.progress);
        };
        img.onerror = () => {
            throw new Error(`Failed to load image with URL: ${url}`);
        };
        img.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                this.progress = percentComplete;
                this.onProgressChanged.notify(this.progress);
            }
        };
    }

    public getProgress(): number {
        return this.progress;
    }
}
