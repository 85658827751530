import { IndoorKartCheckpointsImg, asphaltImg, obstaclesImg, ceilingImg, itemsImg } from '../../assets/img/tracks/Indoor-Kart';
import { ASPHALT, GRASS } from '../GroundConfigs';
import { KART } from '../Racers';
import { RaceConfig } from '../../types/RaceConfig';

const HALF_WIDTH = 645 / 2;
const HALF_HEIGHT = 391 / 2;

export const INDOOR_KART: RaceConfig = {
    id: 'k',
    trackId: 'I',
    name: 'Indoor-Kart',
    heroTime: Math.ceil(25.7 * 60),
    startRacerState: {
        position: { x: 400 - HALF_WIDTH, y: -319 + HALF_HEIGHT },
        direction: Math.PI / 2,
        speed: 0,
    },
    coverRacerState: {
        position: { x: 428 - HALF_WIDTH, y: -88 + HALF_HEIGHT },
        direction: -Math.PI,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: IndoorKartCheckpointsImg,
    },
    orderedGrounds: [
        {
            ...ASPHALT,
            imgUrl: asphaltImg,
        },
    ],
    collisionImgUrl: obstaclesImg,
    ceilingImgUrl: ceilingImg,
    itemsImgUrl: itemsImg,
    racer: KART,
    defaultGrip: GRASS.grip,
    numItems: 1,
};
