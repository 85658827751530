import type { EssentialAsyncProviders, EssentialProviders, Providers } from 'types/Providers';
import type { ProgressManager } from 'util/ProgressManager';
import awsExports from './aws-exports';
import { AuthManager, DefaultAuthManager } from 'util/AuthManager';
import { CloudWatchManager } from 'util/CloudWatchManager';
import { DefaultLocalOwner } from 'util/LocalOwner';

export const createAuthManager = async (progressManager: ProgressManager) => {
    const { Auth, Hub } = await import('aws-amplify');
    await configureAmplify();
    const awsConfig = await getAwsConfig();
    const authManager = new DefaultAuthManager(progressManager, awsConfig, Auth, Hub);
    await authManager.init();
    return authManager as AuthManager;
};

const getAwsConfig = async () => {
    const { config } = await import('aws-sdk');
    config.update({ region: awsExports.aws_project_region });
    return config;
};

const configureAmplify = async () => {
    const { Amplify } = await import('aws-amplify');
    awsExports.oauth.redirectSignIn = window.location.origin + '/';
    console.log('configured redirectSignIn: ', awsExports.oauth.redirectSignIn);
    awsExports.oauth.redirectSignOut = window.location.origin + '/';
    console.log('configured redirectSignOut: ', awsExports.oauth.redirectSignIn);
    Amplify.configure(awsExports);
};

export const loadEssentialAsyncProviders = async (
    preloadedProviders: Partial<Providers> & EssentialProviders
): Promise<EssentialAsyncProviders> => {
    const { CloudWatchLogs } = await import('aws-sdk');

    const authManager = preloadedProviders.authManager || (await createAuthManager(preloadedProviders.progressManager));
    const localOwner = preloadedProviders.localOwner || new DefaultLocalOwner();
    const cloudWatchManager = new CloudWatchManager(authManager, localOwner, () => new CloudWatchLogs());

    preloadedProviders.logging.init(cloudWatchManager);

    return { authManager, localOwner, ...preloadedProviders };
};
