import { Observable } from '@comher.de/commons';

export interface SyncKeyValueStore {
    get(key: string): string | null;
    set(key: string, value: string | null): void;
    observe(key: string): Observable<string | null>;
}

export class LocalState implements SyncKeyValueStore {
    private observables = new Map<string, Observable<string | null>>();

    get(key: string): string | null {
        return localStorage.getItem(key);
    }
    set(key: string, value: string | null): void {
        if (!this.observables.has(key)) this.observables.set(key, new Observable<string | null>());
        if (this.get(key) === value) return;
        if (value === null) localStorage.removeItem(key);
        else localStorage.setItem(key, value);
        this.observables.get(key)!.notify(value);
    }
    observe(key: string): Observable<string | null> {
        if (!this.observables.has(key)) {
            this.observables.set(key, new Observable<string | null>());
        }
        return this.observables.get(key)!;
    }
}
