import { useState } from 'react';
import { useEvent } from './useEvent';
import { ProgressManager } from 'util/ProgressManager';

export const useProgress = (progressManager: ProgressManager) => {
    const [progress, setProgress] = useState(progressManager.getProgress());

    useEvent(
        progressManager.onProgressChanged,
        async (value) => {
            setProgress(value);
        },
        []
    );

    return progress;
};
