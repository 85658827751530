export interface LocalOwner {
    getId(): Promise<string>;
}

export class DefaultLocalOwner implements LocalOwner {
    private readonly KEY = 'LOCAL_OWNER';
    private readonly PREFIX = 'LOCAL_';
    private id: string | null = null;

    private generateRandomUserID(): string {
        return this.PREFIX + Math.random().toString(36).substr(2) + Date.now().toString(36);
    }

    async getId() {
        if (!this.id) {
            this.id = localStorage.getItem(this.KEY);
            if (this.id) console.log('Loaded LocalOwner-ID: ' + this.id);
            else {
                // generate random hash string
                this.id = this.generateRandomUserID();
                localStorage.setItem(this.KEY, this.id);
                console.info('Generated and stored new LocalOwner-ID: ' + this.id);
            }
        }
        return this.id;
    }
}
