import { Size } from '../types/Size';
import { ScreenOrientation } from '../types/ScreenOrientation';
import { Observable } from '@comher.de/commons';

type SizeObservable = {
    onresize: (((this: GlobalEventHandlers, ev: UIEvent) => any) & ((this: Window, ev: UIEvent) => any)) | null;
    innerWidth: number;
    innerHeight: number;
};

export interface SizeObserver {
    onChange: Observable<Size>;
    getScreenOrientation: () => ScreenOrientation;
    getWindowSize: () => Size;
    getAppSize: () => Size;
    getModalSize: () => Size;
    getBaseFontSize: () => number;
    getModalFontSize: () => number;
}

export class DefaultSizeObserver implements SizeObserver {
    public onChange = new Observable<Size>();

    private currentSize: Size = {
        width: 0,
        height: 0,
    };

    constructor(private readonly window: SizeObservable) {
        this.notify();
        window.onresize = () => {
            this.notify();
            setTimeout(() => {
                this.notify();
            }, 100);
            setTimeout(() => {
                this.notify();
            }, 1000);
        };
    }

    public getAppSize = () => {
        const size = this.getWindowSize();
        const appSize = { width: Math.min(size.width, (size.height * 3) / 4), height: size.height };
        return appSize;
    };

    public getModalSize = () => {
        const size = this.getWindowSize();
        const modalSize = { width: size.width - 64, height: size.height - 64 };
        return modalSize;
    };

    public getBaseFontSize = () => {
        const { width, height } = this.getAppSize();
        return 0.055 * Math.min(width, height / 2);
    };

    public getModalFontSize = () => {
        const { width, height } = this.getModalSize();
        return 0.06 * Math.min(width, height / 2);
    };

    private notify = () => {
        const size = this.getWindowSize();
        if (this.currentSize.width === size.width && this.currentSize.height === size.height) return;
        this.onChange.notify(size);
    };

    public getScreenOrientation(): ScreenOrientation {
        if (this.window.innerWidth >= this.window.innerHeight) {
            return 'landscape';
        } else {
            return 'portrait';
        }
    }

    public getWindowSize = (): Size => ({
        width: this.window.innerWidth,
        height: this.window.innerHeight,
    });
}
