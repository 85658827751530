import { Observable } from '@comher.de/commons';
import { useEffect } from 'react';

export const useEvent = <T>(observable: Observable<T>, observer: (value: T) => Promise<void>, deps?: React.DependencyList) => {
    useEffect(() => {
        observable.attach(observer);
        return () => {
            observable.detach(observer);
        };
    }, [observable, ...(deps ?? [])]);
};
