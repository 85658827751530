import { Observable } from '@comher.de/commons';
import { ProgressSource } from 'types/ProgressSource';

const FINISH_DELAY = 500;
export class PromiseProgressSource implements ProgressSource {
    private isFinished = false;
    public readonly name: string;

    onProgressChanged = new Observable<number>();

    public constructor(public promise: Promise<any>, name: string) {
        this.name = name;
        promise.then(() => {
            window.setTimeout(() => {
                this.isFinished = true;
                this.onProgressChanged.notify(1);
            }, FINISH_DELAY);
        });
    }

    public getProgress() {
        return this.isFinished ? 1 : 0;
    }
}
