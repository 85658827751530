export const Colors = {
    white: '#FFF',
    light: '#AAA',
    middle: '#666',
    dark: '#222',
    black: '#000',
    /*--------*/
    hero: '#327eff',
    gold: '#D4AF37',
    silver: '#C0C0C0',
    bronze: '#CD7F32',
    star: '#FFC300',
    /*--------*/
    positive: '#6F6',
    negative: '#F66',
    neutral: '#48F',
    disabled: 'rgba(150, 150, 150, 0.25)',
    /*--------*/
    grass: '#285C29',
};
