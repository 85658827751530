import { Dimension } from '@comher.de/geometry';
import type { Config } from '../types/Config';
import { Colors } from 'theme/Colors';

export const CONFIG: Config = {
    storageKey: 'PIXEL-RACER',
    avgScoreToUnlockFinalRace: 4.01,
    splashScreenTimeout: 4000,
    fps: 60,
    raceBackgroundColor: Colors.grass,
    ghostOpacity: 0.25,
    gameDimension: new Dimension(250, (250 * 16) / 9),
    pfcPrice: 0.2,
    orderFee: 0.3,
    defaultPfcItemCount: 10,
    progressManagerTimeout: 10000,
    progressManagerMinDelay: 1000,
    splitTimeTimeout: 1500,
    showEndMessageDelay: 500,
};
