import { useState } from 'react';
import { useEvent } from './useEvent';
import { SizeObserver } from 'util/SizeObserverUtil';

export const useAppSize = (sizeObserver: SizeObserver) => {
    const [appSize, setAppSize] = useState(sizeObserver.getAppSize());

    useEvent(
        sizeObserver.onChange,
        async () => {
            setAppSize(sizeObserver.getAppSize());
        },
        []
    );

    return appSize;
};
