import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';

export const initI18n = async () => {
    // exporting i18n to global scope for testing.
    // @ts-ignore
    window.i18n = i18n;

    await i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            supportedLngs: ['en', 'de'],
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
        });

    // Configuring language for Authenticator
    I18n.putVocabularies(translations);
    I18n.putVocabulariesForLanguage('de', {
        'Enter your Password': 'Passwort eingeben',
        'Enter your Email': 'E-Mail eingeben',
        'Please confirm your Password': 'Passwort bestätigen',
        'Sign Up with Apple': 'Mit Apple registrieren',
    });
    I18n.putVocabulariesForLanguage('en', {
        'Enter your Password': 'Enter Password',
        'Enter your Email': 'Enter Email',
        'Please confirm your Password': 'Confirm Password',
    });

    I18n.setLanguage(i18n.language);
};

export default i18n;
