import { useState } from 'react';
import { useEvent } from './useEvent';
import { SizeObserver } from 'util/SizeObserverUtil';

export const useModalFontSize = (sizeObserver: SizeObserver) => {
    const [fontSize, setFontSize] = useState(sizeObserver.getModalFontSize());

    useEvent(
        sizeObserver.onChange,
        async () => {
            setFontSize(sizeObserver.getModalFontSize());
        },
        []
    );

    return fontSize;
};
