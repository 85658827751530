import 'util/Logging';
import { initI18n } from 'util/i18n';
import './index.css';
import './loadFonts';
import App from './App';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { PreloadImageProgressSource } from 'util/PreloadImageProgressSource';
import { TRACKS } from 'data/tracks/Tracks';
import { background } from 'assets/img';
import { bannerWide } from 'assets/img/banner';
import type { EssentialAsyncProviders, Providers } from 'types/Providers';
import { loadEssentialProviders } from 'essentialProviders';
import { loadEssentialAsyncProviders } from 'essentialAsyncProviders';

async function loadAndGetProviders(preloadedProviders: Partial<Providers> & EssentialAsyncProviders) {
    const { getProviders } = await import('./providers');
    return getProviders(preloadedProviders);
}

const essentialProviders = loadEssentialProviders({});

initI18n();

const container = document.getElementById('root');
const root = createRoot(container!);

const { progressManager } = essentialProviders;

const bannerPromise = progressManager.addProgressSource(new PreloadImageProgressSource(bannerWide)).promise;

const backgroundPromise = progressManager.addPromise(
    bannerPromise.then(() => new PreloadImageProgressSource(background).promise),
    'Preload Background'
);

const essentialAsyncProvidersPromise = progressManager.addPromise(
    loadEssentialAsyncProviders(essentialProviders),
    'Initializing essential async Providers'
);

const loadingScreenPromise = Promise.all([backgroundPromise, bannerPromise, essentialAsyncProvidersPromise]);

const galleryImgPromise = progressManager.addPromise(
    loadingScreenPromise.then(() => new PreloadImageProgressSource(TRACKS[0].racer.galleryImgSrc).promise),
    'Preload Gallery Image'
);

const providersPromise = progressManager.addPromise(
    loadingScreenPromise.then(async () => {
        const essentialAsyncProviders = { ...essentialProviders, ...(await essentialAsyncProvidersPromise) };
        return loadAndGetProviders(essentialAsyncProviders);
    }),
    'initialize Providers'
);

const providersAndAllPromise = progressManager.addPromise(
    providersPromise.then(async (providers) => {
        await Promise.all([providers.stateUtil.setSelectedTrack(providers.tracks[0]), galleryImgPromise]);
        return providers;
    }),
    'select first track'
);

root.render(
    // <React.StrictMode>
    <App {...essentialProviders} providersPromise={providersAndAllPromise} />
    // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

serviceWorkerRegistration.updateSwOnVisibilityChange();

// document.addEventListener('visibilitychange', () => {
//     if (document.visibilityState === 'visible') window.location.reload();
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
