import type { CloudWatchManager } from './CloudWatchManager';

export type LogLevel = 'DEBUG' | 'LOG' | 'INFO' | 'WARN' | 'ERROR';

export const LS_KEY_LOG_LEVEL = 'LOG_LEVEL';
export const LS_KEY_LOG_ANALYTICS = 'LOG_ANALYTICS';
const logLevels = ['DEBUG', 'LOG', 'INFO', 'WARN', 'ERROR'];

const logLevelToOriginalLoggerMap: Record<LogLevel, typeof console.log> = {
    DEBUG: console.debug,
    LOG: console.log,
    INFO: console.info,
    WARN: console.warn,
    ERROR: console.error,
};

export class Logging {
    private cloudWatchManager: CloudWatchManager | null = null;

    private resolveReady: () => void = () => {};

    private ready = new Promise<void>((resolve) => {
        this.resolveReady = resolve;
    });

    constructor() {
        this.initLogFunctions();
        this.initCatchAll();
    }

    public init = (cloudWatchManager: CloudWatchManager) => {
        this.cloudWatchManager = cloudWatchManager;
        this.resolveReady();
    };

    private initCatchAll = () => {
        window.addEventListener('error', (e) => {
            if (this.checkLoadingFailed(e.error?.message)) this.logLoadingError(e.error);
            else this.log('ERROR', 'UNCAUGHT ERROR: ', e.error);
        });

        window.addEventListener('unhandledrejection', (e) => {
            if (this.checkLoadingFailed(e.reason?.message)) this.logLoadingError(e.reason);
            else this.log('ERROR', 'UNHANDLED REJECTION: ', e.reason);
        });
    };

    private checkLoadingFailed(message: string): boolean {
        if (typeof message !== 'string') return false;
        const pattern = /^Loading .* failed\.$/;
        return pattern.test(message);
    }

    private logLoadingError = (errorMsg: any) => {
        this.log('WARN', errorMsg, ' - Reloading page...');
        // window.location.reload();
    };

    private log = async (logLevel: LogLevel, ...args: any[]) => {
        if (!this.hasRequiredLogLevel(logLevel)) return;
        await this.ready;

        logLevelToOriginalLoggerMap[logLevel]('# ', ...args);

        Logging.getLogAnalytics() && this.cloudWatchManager!.logEventToCloudWatch(logLevel, ...args);
    };

    public static setLogLevel = (level: LogLevel) => {
        localStorage[LS_KEY_LOG_LEVEL] = level;
    };

    public static setLogAnalytics = (value: boolean) => {
        localStorage[LS_KEY_LOG_ANALYTICS] = value;
    };

    public static getLogLevel = (): LogLevel => {
        return localStorage[LS_KEY_LOG_LEVEL] ?? 'INFO';
    };

    public static getLogAnalytics = (): boolean => {
        return localStorage[LS_KEY_LOG_ANALYTICS] === 'true' || window.location.hostname !== 'localhost';
    };

    private getActiveLogLevels = (): LogLevel[] => logLevels.slice(logLevels.indexOf(Logging.getLogLevel())) as LogLevel[];

    public hasRequiredLogLevel = (level: LogLevel) => {
        return this.getActiveLogLevels().includes(level);
    };

    private initLogFunctions() {
        console.debug = (...args: any[]) => {
            this.log('DEBUG', ...args);
        };

        console.log = (...args: any[]) => {
            this.log('LOG', ...args);
        };

        console.info = (...args: any[]) => {
            this.log('INFO', ...args);
        };

        console.warn = (...args: any[]) => {
            // Ignore DataStore trying to sync without authentication
            if (args[0]?.includes('DataStore - User is unauthorized to query syncData')) return;
            if (args[0]?.includes('DataStore - User is unauthorized to query syncReferrals')) return;
            if (args[0]?.includes('DataStore') && (args[1]?.message == 'Error: No current user' || args[1]?.message == 'Unauthorized'))
                return;

            // Ignore PIXI Deprecation Warning
            if (args[0]?.includes('at Generator.next') || args[0]?.includes('at new GraphicsEngine')) return;

            this.log('WARN', ...args);
        };

        console.error = (...args: any[]) => {
            // Downgrade DataStore failed to sync without authentication
            if (args[0]?.includes('DataStore - Sync processor retry error')) {
                this.log('DEBUG', 'DataStore failed to sync without authentication.');
                return;
            }
            this.log('ERROR', ...args);
        };
    }
}
