import { Observable } from '@comher.de/commons';
import type { Auth, Hub } from 'aws-amplify';
import type { ProgressManager } from './ProgressManager';
import type { Config } from 'aws-sdk';

export interface AuthManager {
    userIdObservable: Observable<string | null>;
    getUserId(): string | null;
    getEmail(): Promise<string | null>;
    isAuthenticated(): boolean;
    signOut(): Promise<void>;
    signIn(email: string, password: string): Promise<string>;
}

export class DefaultAuthManager implements AuthManager {
    private userId: string | null = null;

    public userIdObservable: Observable<string | null> = new Observable();

    constructor(private progressManager: ProgressManager, private awsConfig: Config, private auth: typeof Auth, private hub: typeof Hub) {}

    public init = async () => {
        await this.updateUser();
        this.hub.listen('auth', this.listener);
    };

    private setUserId = async (userId: string | null) => {
        if (this.userId !== userId) {
            this.userId = userId;
            const promise = this.userIdObservable.notify(userId);
            this.progressManager.addPromise(promise, 'userIdObservable.notify');
            await promise;
        }
    };

    public getUserId = () => this.userId;
    public isAuthenticated = () => !!this.userId;

    public getEmail = async () => {
        try {
            const user = await this.auth.currentAuthenticatedUser();
            const userAttributes = await this.auth.userAttributes(user);
            return userAttributes.find((a) => a.Name === 'email')?.Value || null;
        } catch (e) {
            return null;
        }
    };

    public signOut = async () => {
        if (!this.isAuthenticated()) throw new Error('Already signed out');
        const promise = new Promise<void>((resolve, reject) => {
            this.userIdObservable.attach(async (userId) => {
                if (!userId) resolve();
                else reject(new Error('Sign out failed'));
            });
            this.auth.signOut();
        });
        this.progressManager.addPromise(promise, 'Auth.signOut');
        return promise;
    };

    public signIn = async (email: string, password: string) => {
        if (this.isAuthenticated()) throw new Error('Already signed in with user: ' + this.userId);
        const promise = new Promise<string>((resolve, reject) => {
            this.userIdObservable.attach(async (userId) => {
                if (userId) resolve(userId);
                else reject(new Error('Sign in failed'));
            });
            this.auth.signIn(email, password);
        });
        this.progressManager.addPromise(promise, 'Auth.signIn');
        return promise;
    };

    private listener = async (data: any) => {
        switch (data.payload.event) {
            case 'signIn':
                await this.updateUser();
                console.info('signIn detected');
                break;
            case 'signOut':
                await this.updateUser();
                console.info('signOut detected');
                break;
        }
    };

    public getAuthCredentials = async () => {
        const credentials = await this.auth.currentCredentials();
        return credentials;
    };

    private updateUser = async () => {
        const promise = (async () => {
            try {
                const user = await this.auth.currentAuthenticatedUser();
                await this.setUserId(user.attributes.sub);
            } catch (e) {
                await this.setUserId(null);
            }
            try {
                const credentials = await this.auth.currentCredentials();
                this.awsConfig.update({ credentials });
            } catch (e) {
                console.error('Error updating AWS credentials', e);
            }
        })();
        this.progressManager.addPromise(promise, 'updateUser');
        return promise;
    };
}
