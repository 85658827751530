import type { CloudWatchLogs } from 'aws-sdk';

import type { AuthManager } from './AuthManager';
import { getAmplifyEnv } from './getAmplifyEnv';
import packageJson from '../../package.json';
import type { LocalOwner } from './LocalOwner';
import type { LogLevel } from './Logging';

const version = packageJson.version;

const MAX_LOGS_IN_TIME_OUT = 100;
const TIMEOUT = 1000;
export class CloudWatchManager {
    private cloudWatchLogs: CloudWatchLogs;

    private localOwnerId: string | null = null;
    private ownerId: string | null = null;
    private env;
    private logsInTimeOutCounter: number = 0;
    private disabled = false;

    private ready;

    constructor(private authManager: AuthManager, private localOwner: LocalOwner, private cloudWatchLogsFactory: () => CloudWatchLogs) {
        this.cloudWatchLogs = cloudWatchLogsFactory();
        this.ready = this.recognizeUpdatedCredentials();
        authManager.userIdObservable.attach(() => this.recognizeUpdatedCredentials());

        //@ts-ignore
        console.cloud = (message: string) => this.logEventToCloudWatch(message);

        this.env = getAmplifyEnv();

        this.updateLocalOwner();
        this.updateOwner();

        setInterval(() => {
            if (this.logsInTimeOutCounter >= MAX_LOGS_IN_TIME_OUT) {
                this.disabled = true;
            } else {
                this.logsInTimeOutCounter = 0;
            }
        }, TIMEOUT);
    }

    private updateLocalOwner = async () => {
        this.localOwnerId = await this.localOwner.getId();
    };

    private updateOwner = async () => {
        this.authManager.userIdObservable.attach(async (userId) => {
            this.ownerId = userId;
        });
        this.ownerId = await this.authManager.getUserId();
    };

    private getLogEntry = (logLevel: LogLevel, message: string) => {
        const entry = {
            message,
            localOwner: this.localOwnerId ?? 'unknown',
            owner: this.ownerId ?? 'unknown',
            env: this.env,
            logLevel,
            version,
        };
        return entry;
    };

    private recognizeUpdatedCredentials = async () => {
        this.cloudWatchLogs = this.cloudWatchLogsFactory();
    };

    public logEventToCloudWatch = async (logLevel: LogLevel, ...args: any[]) => {
        this.logsInTimeOutCounter++;

        if (this.disabled || this.logsInTimeOutCounter >= MAX_LOGS_IN_TIME_OUT) return;

        const stringifiedArgs = args
            .filter((arg) => arg !== undefined)
            .map((arg) => {
                let str = typeof arg === 'string' ? arg : JSON.stringify(arg);
                str = str.length > 500 ? str.substring(0, 497) + '...' : str;
                return str;
            })
            .join('');

        const message = stringifiedArgs.length > 2000 ? stringifiedArgs.substring(0, 497) + '...' : stringifiedArgs;
        const data = this.getLogEntry(logLevel, message);

        const params = {
            logGroupName: 'Client-Logs',
            logStreamName: 'DefaultClientLogs',
            logEvents: [
                {
                    timestamp: new Date().getTime(),
                    message: JSON.stringify(data),
                },
            ],
        };

        await this.ready;

        if (!this.cloudWatchLogs) {
            throw new Error('CloudWatchLogs not initialized');
        }

        this.cloudWatchLogs.putLogEvents(params, (err) => {
            if (err) {
                this.disabled = true;
                window.setTimeout(() => {
                    this.disabled = false;
                }, 10000);
                console.error('Error logging to CloudWatch', err);
                if (err.code === 'ExpiredTokenException') {
                    window.location.reload();
                }
            }
        });
    };
}
