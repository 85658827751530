import { Observable } from '@comher.de/commons';
import { ProgressSource } from 'types/ProgressSource';

export class ProgressTimer implements ProgressSource {
    private progress: number = 0;

    public onProgressChanged: Observable<number> = new Observable();

    public promise = new Promise<void>((resolve, reject) => {
        this.onProgressChanged.attach(async (progress) => {
            if (progress === 1) {
                resolve();
            }
        });
    });

    constructor(private delay: number, public name: string) {
        this.updateProgress(0);
        setTimeout(() => {
            this.updateProgress();
            setTimeout(() => {
                this.updateProgress();
                setTimeout(() => {
                    this.updateProgress();
                    setTimeout(() => {
                        this.updateProgress();
                        setTimeout(() => {
                            this.updateProgress();
                            setTimeout(() => {
                                this.updateProgress();
                                setTimeout(() => {
                                    this.updateProgress();
                                    setTimeout(() => {
                                        this.updateProgress();
                                        setTimeout(() => {
                                            this.updateProgress();
                                            setTimeout(() => {
                                                this.updateProgress();
                                            }, this.delay / 10);
                                        }, this.delay / 10);
                                    }, this.delay / 10);
                                }, this.delay / 10);
                            }, this.delay / 10);
                        }, this.delay / 10);
                    }, this.delay / 10);
                }, this.delay / 10);
            }, this.delay / 10);
        }, this.delay / 10);
    }

    private updateProgress(set: number = this.progress + 0.1) {
        this.progress = Math.round(set * 100) / 100;
        this.onProgressChanged.notify(this.progress);
    }

    getProgress(): number {
        return this.progress;
    }
}
