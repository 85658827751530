import { Observable, ThreadSafeExecuter } from '@comher.de/commons';
import { ProgressSource } from 'types/ProgressSource';
import { ProgressTimer } from './ProgressTimer';
import { PromiseProgressSource } from './PromiseProgressSource';
import { LocalState } from './LocalState';
import { Config } from 'types/Config';

export type ProgressSourceDetails = { name: string; progress: number };

export interface ProgressManager extends ProgressSource {
    addProgressSource(progressSource: ProgressSource): ProgressSource;
    getProgressSourcesDetails(): ProgressSourceDetails[];
    addPromise(promise: Promise<any>, name: string): Promise<any>;
    addTimer(timeout: number, name?: string): ProgressSource;
    addMinDelayTimer(): void;
    disable(): void;
    enable(): void;
    ensureVisibility: () => Promise<void>;
}

const LS_KEY_FIRST_LOAD = 'FIRST_LOAD';

export class DefaultProgressManager implements ProgressManager {
    public readonly name = 'ProgressManager';

    private progressSources: ProgressSource[] = [];
    private disabled = false;
    private lastProgress = 0;

    public onProgressChanged = new Observable<number>();

    public promise = new Promise<void>((resolve, reject) => {
        this.onProgressChanged.attach(async (progress) => {
            if (progress === 1) {
                resolve();
            }
        });
    });

    private timeoutId: NodeJS.Timeout | null = null;

    constructor(private safeExecuter: ThreadSafeExecuter, private localState: LocalState, private config: Config) {}

    getProgressSourcesDetails(): ProgressSourceDetails[] {
        return this.progressSources.map((source) => ({ name: source.name, progress: source.getProgress() }));
    }

    disable() {
        if (this.disabled) return;
        this.disabled = true;
        this.onProgressChanged.notify(this.getProgress());
    }

    private addInitialLoadProrgressSourceIfNecessary() {
        if (this.localState.get(LS_KEY_FIRST_LOAD) !== 'false') {
            this.localState.set(LS_KEY_FIRST_LOAD, 'false');
            this.addTimer(this.config.splashScreenTimeout, 'SplashScreenTimeout');
        }
    }

    enable() {
        if (!this.disabled) return;
        this.disabled = false;
        this.onProgressChanged.notify(this.getProgress());
    }

    private calcProgress(): number {
        const progress =
            this.progressSources.map((source) => source.getProgress()).reduce((a, b) => a + b, 0) / this.progressSources.length;
        if (Math.round(progress * 1000) / 1000 === 1) return 1;

        const reductionFactor = Math.max(this.progressSources.length - 2, 1) / this.progressSources.length;
        const reducedProgress = Math.max(progress * reductionFactor, this.lastProgress);

        return reducedProgress;
    }

    getProgress(): number {
        if (this.disabled || this.progressSources.length === 0) {
            return 1;
        }
        const progress = this.calcProgress();
        this.lastProgress = progress;

        if (progress === 1) {
            this.clearProgressSources();
        }
        return progress;
    }

    private clearProgressSources(): Promise<void> {
        return this.safeExecuter.executeThreadSafe(async () => {
            this.timeoutId && clearTimeout(this.timeoutId);
            this.lastProgress = 0;
            this.progressSources = [];
        });
    }

    private updateTimeout(): void {
        this.timeoutId && clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(async () => {
            const progress = this.getProgress();
            if (progress == 1) return;
            console.warn(
                'ProgressManager: Progress is stuck at ' +
                    progress * 100 +
                    '% after ' +
                    this.config.progressManagerTimeout +
                    's. Remaining Sources: ' +
                    this.progressSources
                        .filter((source) => source.getProgress() < 1)
                        .map((source) => source.name)
                        .join(', ')
            );
            // console.log('ProgressManager: Clearing Progress Sources');
            // await this.clearProgressSources();
            // this.onProgressChanged.notify(this.getProgress());
            console.warn('Reloading page...');
            window.location.reload();
        }, this.config.progressManagerTimeout);
    }

    addProgressSource(progressSource: ProgressSource): ProgressSource {
        this.addInitialLoadProrgressSourceIfNecessary();
        this.safeExecuter.executeThreadSafe(async () => {
            let firstSource = false;
            if (this.progressSources.length === 0) firstSource = true;
            this.progressSources.push(progressSource);
            progressSource.onProgressChanged.attach(async () => {
                this.updateTimeout();
                this.onProgressChanged.notify(this.getProgress());
            });
            if (firstSource) {
                this.addMinDelayTimer();
            }
            this.onProgressChanged.notify(this.getProgress());
        });
        return progressSource;
    }

    addPromise(promise: Promise<any>, name: string): Promise<any> {
        return this.addProgressSource(new PromiseProgressSource(promise, name)).promise;
    }

    addTimer(timeout: number, name: string): ProgressSource {
        return this.addProgressSource(new ProgressTimer(timeout, name));
    }

    addMinDelayTimer(): void {
        this.addTimer(this.config.progressManagerMinDelay, 'MinDelay');
    }

    async ensureVisibility() {
        const startupTimer = new Promise((resolve) => setTimeout(resolve, 100));
        this.addPromise(startupTimer, 'Ensure Visibilty');
        return startupTimer as Promise<void>;
    }
}
