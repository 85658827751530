import { RaceConfig } from '../../types/RaceConfig';
import { COMING_HOME, COMING_HOME_DRUNK } from './Track_ComingHome';
import { INDOOR_KART } from './Track_IndoorKart';
import { ROCKY_HILL, ROCKY_HILL_ENDURANCE, ROCKY_HILL_INTRO, ROCKY_HILL_SHOWDOWN } from './Track_RockyHill';
import { SPRINGFIELD_RW_INTRO, SPRINGFIELD_RW_SHOWDOWN } from './Track_SpringfieldRaceway';
import { INTRO, SLALOM, SLINGSHOT, SUPER_ELK_TEST, ULTRA_ELK_TEST } from './Track_TrainingGrounds';

export const TRACKS: RaceConfig[] = [
    ROCKY_HILL_INTRO,
    INTRO,
    SLALOM,
    SPRINGFIELD_RW_INTRO,
    SLINGSHOT,
    ROCKY_HILL,
    SUPER_ELK_TEST,
    COMING_HOME,
    INDOOR_KART,
    ROCKY_HILL_ENDURANCE,
    COMING_HOME_DRUNK,
    ULTRA_ELK_TEST,
    SPRINGFIELD_RW_SHOWDOWN,
    ROCKY_HILL_SHOWDOWN,
];
