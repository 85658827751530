import { GroundConfig } from '../types/GroundConfig';

export const ASPHALT: GroundConfig = {
    grip: 1,
    name: 'asphalt',
};
export const DIRT: GroundConfig = {
    grip: 0.4,
    name: 'dirt',
};
export const GRASS: GroundConfig = {
    grip: 0.55,
    name: 'grass',
};
