import {
    asphaltImg,
    dirtImg,
    obstaclesImg,
    IntroCheckpointsImg,
    SlingshotCheckpointsImg,
    SlalomCheckpointsImg,
    ElkTestCheckpointsImg,
    items,
    ceiling,
} from '../../assets/img/tracks/Training-Grounds';
import { ASPHALT, DIRT, GRASS } from '../GroundConfigs';
import { FALCON, SCORPION, TURTLE } from '../Racers';
import { RaceConfig } from '../../types/RaceConfig';

const SHARED = {
    trackId: 'T',
    orderedGrounds: [
        {
            ...DIRT,
            imgUrl: dirtImg,
        },
        {
            ...ASPHALT,
            imgUrl: asphaltImg,
        },
    ],
    collisionImgUrl: obstaclesImg,
    ceilingImgUrl: ceiling,
    itemsImgUrl: items,
    numItems: 6,
    defaultGrip: GRASS.grip,
};

export const INTRO: RaceConfig = {
    id: 'i',
    name: 'Intro',
    heroTime: Math.ceil(10.63 * 60),
    startRacerState: {
        position: { x: 405 - 500, y: -1305 + 723 },
        direction: -Math.PI / 3.1,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: IntroCheckpointsImg,
    },
    racer: TURTLE,
    // defaultCompressedGhost:
    //     'O23«11O183»5>3o19>4»3O8o15>19o14>9o15>7o11>6o13O6»6>8o12>9o19O40«12<1o54<8o20<15o7<20o5O3o19<11o25O12«7o16O37o16O7»2>9o32>6o22>12o5>12o11>6o19',
    ...SHARED,
};

export const SLINGSHOT: RaceConfig = {
    id: 's',
    name: 'Slingshot',
    heroTime: Math.ceil(19.05 * 60),
    startRacerState: {
        position: { x: 590 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: SlingshotCheckpointsImg,
    },
    racer: TURTLE,
    coverRacerState: {
        position: { x: 650 - 500, y: 1447 / 2 - 320 },
        direction: -Math.PI * 0.01,
        speed: 0,
    },
    defaultCompressedGhost:
        'O34»5O253»1O23o24O49»4O14o31<6o18O27o18>5o18O11o11<9o9O8o10.13o15O10o27<8o11<9(16<15(17<67«18<13«66O115»6O49»5O85»4O18o17<6o8O8»6O87o16<4o25<5o41',

    ...SHARED,
};

export const SLALOM: RaceConfig = {
    id: 'l',
    name: 'Slalom',
    heroTime: Math.ceil(9.98 * 60),
    startRacerState: {
        position: { x: 720 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    coverRacerState: {
        position: { x: 734 - 500, y: 1447 / 2 - 1294 },
        direction: Math.PI * 0.055,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: SlalomCheckpointsImg,
    },
    racer: TURTLE,
    defaultCompressedGhost:
        'O31»9O16»7O32»6O7o16O15«7O51«14<1o86O7»11O10»11O29«10O19«11<8o27O5»5>2o18>11o17>8o18<7o14O33«18O2o37O3»3O19»19O24»7O16«20',
    ...SHARED,
};

export const SUPER_SLALOM: RaceConfig = {
    id: 'L',
    name: 'Super-Slalom',
    heroTime: 493,
    startRacerState: {
        position: { x: 720 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: SlalomCheckpointsImg,
    },
    racer: SCORPION,
    ...SHARED,
};

export const ELK_TEST: RaceConfig = {
    id: 'e',
    name: 'Elk-Test',
    heroTime: 629,
    startRacerState: {
        position: { x: 875 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    coverRacerState: {
        position: { x: 932 - 500, y: 1447 / 2 - 606 },
        direction: Math.PI * 0.1,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: ElkTestCheckpointsImg,
    },
    racer: TURTLE,
    ...SHARED,
};

export const SUPER_ELK_TEST: RaceConfig = {
    id: 'E',
    name: 'Super-Elk-Test',
    heroTime: Math.ceil(8.32 * 60),
    startRacerState: {
        position: { x: 875 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    coverRacerState: {
        position: { x: 932 - 500, y: 1447 / 2 - 606 },
        direction: Math.PI * 0.1,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: ElkTestCheckpointsImg,
    },
    racer: SCORPION,
    defaultCompressedGhost: 'O288o8.30)6>19o13O16«10O10«13O18o4<11o7<23o10>22o9>7o14O11o18O57',
    ...SHARED,
};

export const ULTRA_ELK_TEST: RaceConfig = {
    id: 'K',
    name: 'Ultra-Elk-Test',
    heroTime: Math.ceil(6.63 * 60),
    startRacerState: {
        position: { x: 875 - 500, y: -675 },
        direction: 0,
        speed: 0,
    },
    coverRacerState: {
        position: { x: 932 - 500, y: 1447 / 2 - 606 },
        direction: Math.PI * 0.1,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: ElkTestCheckpointsImg,
    },
    racer: FALCON,
    ...SHARED,
};
