import { Dialog } from '@material-ui/core';
import { Styles } from 'theme/Styles';
import { useModalFontSize } from 'use-functions/useBaseFontSize';
import { Colors } from 'theme/Colors';
import { CSSProperties } from 'react';
import { SizeObserver } from 'util/SizeObserverUtil';

export type ModalProps = {
    open: boolean;
    sizeObserver: SizeObserver;
    handleClose?: () => void;
    children: React.ReactNode;
    dataTestId: string;
    fullScreen?: boolean;
    style?: { padding?: string };
    onTop?: boolean;
};

const Modal = (props: ModalProps) => {
    const { open, handleClose, children, dataTestId, sizeObserver, fullScreen, style, onTop } = props;
    const modalFontSize = useModalFontSize(sizeObserver);

    const mainContainerStyle: CSSProperties = {
        touchAction: 'none',
        msTouchAction: 'none',

        ...Styles.highlightedBorder,
        lineHeight: '1.1',
        borderRadius: '0.15em',
        color: Colors.white,
        backgroundColor: Colors.black,
        fontSize: modalFontSize,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'silkscreennormal',
        textAlign: 'center',
        padding: '0.75em 0.5em',
        height: 'inherit',
        position: 'relative',
        ...style,
    };

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="xl" fullScreen={fullScreen} style={{ zIndex: onTop ? 100 : 10 }}>
            <div data-testid={dataTestId} style={mainContainerStyle}>
                {children}
            </div>
        </Dialog>
    );
};

export default Modal;
