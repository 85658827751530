/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://ffenxvdetfcynbck2tjgb3vopi.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-c75nelz4cvdunkwp5pltdiktmq",
    "aws_cloud_logic_custom": [
        {
            "name": "purchase",
            "endpoint": "https://d0eeiho7uj.execute-api.eu-central-1.amazonaws.com/beta",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:79917fbe-f852-4ce9-a877-e32050c3947e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_moGyw7ieJ",
    "aws_user_pools_web_client_id": "1k2dmps0af0uhl41aplb3b81oo",
    "oauth": {
        "domain": "pixelracer5a4fa157-5a4fa157-beta.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://dev.pixelracer.martin-hermes.de/,https://alpha.pixelracer.martin-hermes.de/,https://pixelracer.martin-hermes.de/",
        "redirectSignOut": "http://localhost:3000/,https://dev.pixelracer.martin-hermes.de/,https://alpha.pixelracer.martin-hermes.de/,https://pixelracer.martin-hermes.de/",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
