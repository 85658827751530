import { Observable } from '@comher.de/commons';
import { useEvent } from './useEvent';
import { useEffect, useState } from 'react';
import { jsonCopy } from 'util/jsonCopy';

export const NONE = 'NONE_OBSERVED' as const;

export const useObservableState = <TObserved, TResult>(props: {
    observable: Observable<TObserved>;
    evaluate: (observed: TObserved) => TResult;
    initialResult: TResult;
    initialObserved: TObserved | typeof NONE;
    deps?: React.DependencyList;
}) => {
    const { observable, evaluate, initialObserved, initialResult, deps = [] } = props;

    const [counter, setCounter] = useState<number>(0);
    const [observed, setObserved] = useState<TObserved | typeof NONE>(initialObserved);
    const [result, setResult] = useState<TResult>(initialResult);

    useEvent(observable, async (observed: TObserved) => {
        typeof observed != 'undefined' ? (observed = jsonCopy(observed)) : observed;
        setObserved(observed);
        setCounter((counter) => counter + 1);
    });

    useEffect(() => {
        if (observed === NONE) return;

        const result = evaluate(observed);
        setResult(result);
    }, [observed, counter, ...deps]);

    return result;
};
