import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faLock,
    faStar,
    faBars,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowCircleRight,
    faArrowCircleLeft,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronCircleDown,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faUserCircle,
    faTrophy,
    faMoneyBillAlt,
    faRedoAlt,
    faCircleQuestion,
    faShare,
    faShareAlt,
    faGem,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    //@ts-ignore  Incompatible types due to different fontawesome versions!
    faStar,
    faLock,
    faArrowCircleUp,
    faArrowCircleDown,
    faArrowCircleRight,
    faArrowCircleLeft,
    faChevronRight,
    faChevronLeft,
    faChevronUp,
    faChevronDown,
    faUserCircle,
    faTrophy,
    faMoneyBillAlt,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronCircleUp,
    faChevronCircleDown,
    faBars,
    faRedoAlt,
    faCircleQuestion,
    faShare,
    faShareAlt,
    faGem
);
