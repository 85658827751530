import awsExports from '../aws-exports';

let env: string | null = null;
export const getAmplifyEnv = (): string => {
    if (env) return env;
    const str = awsExports.aws_cloud_logic_custom[0].endpoint;
    const parts = str.split('/');
    env = parts[parts.length - 1];
    console.debug('Amplify ENV: ' + env);
    return env;
};
