import { ThreadSafeExecuter } from '@comher.de/commons';
import { CONFIG } from 'data/config';
import { EssentialProviders, Providers } from 'types/Providers';
import { LocalState } from 'util/LocalState';
import { Logging } from 'util/Logging';
import { DefaultProgressManager } from 'util/ProgressManager';
import { DefaultSizeObserver } from 'util/SizeObserverUtil';

export const loadEssentialProviders = (preloadedProviders: Partial<Providers>): EssentialProviders => {
    const logging = preloadedProviders.logging || new Logging();
    const threadSafeExecuterFactory = preloadedProviders.safeExecuterFactory || (() => new ThreadSafeExecuter());
    const localState = preloadedProviders.localState || new LocalState();
    const config = preloadedProviders.config || CONFIG;
    const progressManager =
        preloadedProviders.progressManager || new DefaultProgressManager(threadSafeExecuterFactory(), localState, config);
    const sizeObserver = preloadedProviders.sizeObserver || new DefaultSizeObserver(window);
    return { localState, progressManager, config, sizeObserver, logging, ...preloadedProviders };
};
