import {
    asphaltImg,
    dirtImg,
    obstaclesImg,
    ceilingImg,
    ComingHomeCheckpointsImg,
    ComingHomeDrunkCheckpointsImg,
    itemsImgUrl,
    grassImg,
} from '../../assets/img/tracks/Coming-Home';
import { ASPHALT, DIRT, GRASS } from '../GroundConfigs';
import { HORNET, SCORPION } from '../Racers';
import { RaceConfig } from '../../types/RaceConfig';

const SHARED = {
    trackId: 'C',
    orderedGrounds: [
        {
            ...DIRT,
            imgUrl: dirtImg,
        },
        {
            ...ASPHALT,
            imgUrl: asphaltImg,
        },
        {
            ...GRASS,
            imgUrl: grassImg,
        },
    ],
    collisionImgUrl: obstaclesImg,
    ceilingImgUrl: ceilingImg,
    itemsImgUrl: itemsImgUrl,
    startRacerState: {
        position: { x: 1267 - 750, y: -719 + 500 },
        direction: -Math.PI / 2,
        speed: 0,
    },
    defaultGrip: GRASS.grip,
    numItems: 3,
};

export const COMING_HOME: RaceConfig = {
    id: 'c',
    name: 'Coming-Home',
    heroTime: Math.ceil(14.9 * 60),
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: ComingHomeCheckpointsImg,
    },
    coverRacerState: {
        position: { x: 347 - 750, y: 500 - 352 },
        direction: Math.PI * 0.0,
        speed: 0,
    },
    racer: SCORPION,
    defaultCompressedGhost: 'O318o14.3(24.5)13>59»16O19«3O119o15.29o14>67o10<7o11<30o1>39o22<6o12.104o52<7o91>4o18>4o39',

    ...SHARED,
};

export const COMING_HOME_DRUNK: RaceConfig = {
    id: 'C',
    name: 'Coming-Home-Drunk',
    heroTime: Math.ceil(13.4 * 60),
    coverRacerState: {
        position: { x: 523 - 750, y: 500 - 429 },
        direction: Math.PI * 0.35,
        speed: 0,
    },
    checkPointsConfig: {
        numLaps: 1,
        imgUrl: ComingHomeDrunkCheckpointsImg,
    },
    racer: HORNET,
    defaultCompressedGhost:
        'O19«11O53«7O26»5O84«9O19»5O17»10O6»37>8)29>8»6O15»15O16»2>63o1<19«6O8»17O6»9>19o7>12.12(26.18(1<51(12<30«7<14«5<4o27>3o11>10o3>56o8O8o9<11o28<10«12O12«14O4o13<14«17<9o38.13o5<10o58<7o69',
    ...SHARED,
};
