import { useState } from 'react';
import { Providers } from 'types/Providers';
import { useAsyncEffect } from 'use-functions/useAsyncEffect';
import { LoadingModal } from 'components/shared/modal/LoadingModal';
import { Colors } from 'theme/Colors';
import { useTimeout } from 'use-functions/useTimeout';
import { SizeObserver } from 'util/SizeObserverUtil';
import { ProgressManager } from 'util/ProgressManager';
import { LocalState } from 'util/LocalState';
import React from 'react';

const App = (props: {
    sizeObserver: SizeObserver;
    progressManager: ProgressManager;
    localState: LocalState;
    providersPromise: Promise<Providers>;
}) => {
    const { providersPromise, sizeObserver, progressManager, localState } = props;
    const [PageContainer, setPageContainer] = useState<any>();
    const [providers, setProviders] = useState<Providers | null>(null);
    const [start, setStart] = useState(false);

    useTimeout(
        async () => {
            setStart(true);
        },
        100,
        []
    );

    useAsyncEffect(async () => {
        const PageContainer = React.lazy(async () => {
            await providersPromise;
            return progressManager.addPromise(import('components/PageContainer'), 'Load PageContainer');
        });

        const providers = await providersPromise;
        setPageContainer(PageContainer);
        start && setProviders(providers);
    }, [start, providersPromise]);

    return (
        <div
            style={{
                fontFamily: 'silkscreennormal',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: Colors.dark,

                touchAction: 'none',
                msTouchAction: 'none',
                position: 'relative',
            }}
        >
            {providers && PageContainer && <PageContainer providers={providers} />}
            <LoadingModal sizeObserver={sizeObserver} progressManager={progressManager} localState={localState} />
        </div>
    );
};

export default App;
