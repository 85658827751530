import { useEffect, useState } from 'react';

export const useTimeout = <T>(observer: () => Promise<void>, timeout: number, deps: React.DependencyList) => {
    const [timeoutId, setTimeoutId] = useState<number | null>(null);

    useEffect(() => {
        timeoutId && window.clearTimeout(timeoutId);

        const newTimeoutId = window.setTimeout(() => {
            observer();
        }, timeout);

        setTimeoutId(newTimeoutId);

        return () => window.clearTimeout(newTimeoutId);
    }, deps);
};
